import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./home/Pages/Home";
import ResetPassword from "./users/Pages/ResetPassword";
import BusinessVerification from "./business/Pages/BusinessVerification";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route
          path="/business-verification/:token"
          element={<BusinessVerification />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
