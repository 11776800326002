import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Loading from "../../shared/components/Loading";
import ErrorAlert from "../../shared/components/ErrorAlert";
import SuccessAlert from "../../shared/components/SuccessAlert";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://localsatlas.com">
        Locals Atlas
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const ResetPassword = () => {
  const [user, setUser] = useState();
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const { token } = useParams();

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users/reset/password/${token}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            password: password,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      setUser(data);
    } catch (err) {
      setError(err.message);
    }

    setIsLoading(false);
    setPassword("");
  };

  const clearErrorHandler = () => {
    setError(null);
  };

  const clearMessageHandler = () => {
    setUser(null);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Loading loading={isLoading} />
      <Grid item>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Reset your password
            </Typography>
            <Box
              component="form"
              onSubmit={resetPasswordHandler}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                autoComplete="current-password"
                autoFocus
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                RESET PASSWORD
              </Button>
            </Box>
            {user && (
              <SuccessAlert
                message="Password change successfully"
                onClear={clearMessageHandler}
              />
            )}
            {error && <ErrorAlert error={error} onClear={clearErrorHandler} />}
          </Box>
        </Paper>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
