import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Loading from "../../shared/components/Loading";
import ErrorAlert from "../../shared/components/ErrorAlert";
import SuccessAlert from "../../shared/components/SuccessAlert";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://localsatlas.com">
        Locals Atlas
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const BusinessVerification = () => {
  const values = {
    country: "USA",
    idType: "",
    idNumber: "",
    email: "",
    phone: "",
    address: "",
  };

  const [userInput, setUserInput] = useState(values);
  const [verification, setVerification] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const { token } = useParams();

  const idUsaTypes = ["ein", "ssn", "itin"];

  const changeHandler = (e) => {
    setUserInput((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const submitBusinessVerificationRequestHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/verifications/business/${token}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            country: userInput.country,
            idType: userInput.idType,
            idNumber: userInput.idNumber,
            email: userInput.email,
            phone: userInput.phone,
            address: userInput.address,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      setVerification(data);
    } catch (err) {
      setError(err.message);
    }

    setIsLoading(false);
    setUserInput(values);
  };

  const clearErrorHandler = () => {
    setError(null);
  };

  const clearMessageHandler = () => {
    setVerification(null);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Loading loading={isLoading} />
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{ p: 3, width: { lg: "40%", md: "50%", sm: "flex", xs: "flex" } }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Form to verify business
            </Typography>
            <Box
              component="form"
              onSubmit={submitBusinessVerificationRequestHandler}
            >
              <TextField
                margin="normal"
                id="country"
                name="country"
                fullWidth
                required
                select
                label="Country"
                value={userInput.country}
                onChange={changeHandler}
              >
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="ECU">ECU</MenuItem>
              </TextField>
              <TextField
                margin="normal"
                id="idType"
                name="idType"
                fullWidth
                required
                select
                label="ID Type"
                value={userInput.idType}
                onChange={changeHandler}
              >
                {userInput.country === "USA" ? (
                  idUsaTypes.map((element) => (
                    <MenuItem key={element} value={element}>
                      {element}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="ruc">ruc</MenuItem>
                )}
              </TextField>
              <TextField
                margin="normal"
                id="idNumber"
                name="idNumber"
                fullWidth
                required
                label="ID Number"
                value={userInput.idNumber}
                onChange={changeHandler}
              />
              <TextField
                margin="normal"
                id="email"
                name="email"
                fullWidth
                required
                label="Email"
                value={userInput.email}
                onChange={changeHandler}
              />
              <TextField
                margin="normal"
                id="address"
                name="address"
                fullWidth
                required
                label="Address"
                value={userInput.address}
                onChange={changeHandler}
              />
              <TextField
                margin="normal"
                id="phone"
                name="phone"
                fullWidth
                required
                label="Phone"
                value={userInput.phone}
                onChange={changeHandler}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                SUBMIT REQUEST
              </Button>
            </Box>
            {verification && (
              <SuccessAlert
                message="Business verification request sent successfully"
                onClear={clearMessageHandler}
              />
            )}
            {error && <ErrorAlert error={error} onClear={clearErrorHandler} />}
          </Box>
        </Paper>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Grid>
    </Grid>
  );
};

export default BusinessVerification;
